import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../Navbar";
import image from "../../img/cad2.jpeg";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../Faq";
import WFooter from "../WFooter";
import Footer from "../Footer";
import { SvgElement, icontypesEnum } from "../assets/svgElement";


function AboutDetails() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-20 pt-[72px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-28 md:pb-8  flex justify-start "
          >
            <div className="py-20">
              <div className="text-[white] max-w-fit md:ml-44 pl-2 pr-20 flex justify-start mb-4 bg-black bg-opacity-80">
                <span className="font-[700] md:text-[20px] text-[20px] py-4" > Project Details</span>
              </div>

              <div className="text-[white] md:ml-44 pl-2 pr-20 flex justify-start bg-[#9F0000BA] bg-opacity-80">
                <span className="font-[700] md:text-[48px] text-[20px] py-4" > Clinic At Your Door Step</span>
              </div>
            </div>
          </div>

          
        </div>
      </section>


      <section>
        <div className="pt-10 md:px-20 px-4 pb-5">
          <div className="text-[white] max-w-fit pl-2 pr-20 flex justify-start mb-10 bg-[#FA0000] ">
            <span className="font-[700] md:text-[20px] text-[20px] py-2" > Project Overview</span>
          </div>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">Studies have revealed that Nigerian people hardly access health facilities, most especially the uneducated community members. Proximity, Time factor and poverty are major constraint inhibiting  access to orthodox hospitals.  Hence most community members relied on untrained  health personnel’s  such as Auxiliary Nurses, Traditional Birth Attendance etc.</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            There is a huge misconception of information on various health issues among community members in Nigeria.  Different studies had shown lack of knowledge, inadequate and insincere information on different health issues. Most health issues like Hypertension, Diabetes and Malaria are often misunderstood with regards to causes, prevention and treatment.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            A lot of patients in Nigeria who have been diagnosed of hypertension and diabetes are not compliant with their drugs usage, while some have no access to good drugs and this often results in complications and possible death.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            To reduce the death rates and possible complications of those ailments mentioned, YCP launched a Community Health Outreach program Tagged “CLINIC AT YOUR DOOR STEP” YCP in collaboration with the respective Local Governments and their Community Development Associations in three states of Nigeria namely, Ogun, Ondo and Lagos State.  The project is to educate and create awareness among community members about the dangers of most of these ailments threatening the health of Nigerians and Africa as a whole and possible solution for their prevention and cure.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            The health services rendered by the Clinic at your door steps program include: health  activities, health screening for hypertension, malaria and diabetes; which according to World Health Organization (WHO), reduces the life expectancy of Nigerian people. Other services include Treatment of minor ailments such as malaria, diarrhea and upper respiratory tract infection and referral to primary health centers. These services are carried out on drug revolving basis  at a cost  less than a dollar per client.
          </p>

        </div>

        <div className="px-20">
          <p className="text-[40px] font-[700]">GALLERY</p>
        </div>
        <div className="grid md:grid-cols-4 py-10 gap-2 px-10">
          <span>  <img src="/images/cayds/c1.webp" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c2.webp" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c3.webp" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c4.webp" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c5.webp" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c6.webp" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c7.jpeg" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c8.jpeg" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c9.jpeg" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c10.jpeg" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c11.jpeg" alt="location" style={{}} /></span>
          <span>  <img src="/images/cayds/c12.jpeg" alt="location" style={{}} /></span>
        </div>
      </section>

      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default AboutDetails;
