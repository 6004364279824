import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "./../Navbar";
import image from "../../img/dash.png";
import image3 from "../../img/bg-2.png";
import image2 from "../../img/details.png";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../Faq";

import WFooter from "../WFooter";
import Footer from "../Footer";
import { SvgElement, icontypesEnum } from "../assets/svgElement";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//@ts-ignore
import { Carousel as Carousel2 } from 'react-responsive-carousel';


function Home() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />

     
      {/* <!-- Start block --> */}
      <section className="bg-[white] body-font font-poppins ">
     
      <Carousel2 className="mt-20" autoPlay={true} infiniteLoop={true} stopOnHover={false}>
        <div>
          <div className="">
            <div
              style={{
                backgroundImage: `url(${image3})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: 'center center'

              }}
              className=" md:py-20 bg-contain lg:flex lg:justify-between "
            >

              {/* <div className="md:hidden block pt-16">
                <img src="/images/back2.png" alt="location" />
              </div> */}
              <div className="md:mr-auto place-self-center col-span-7 pt-16  md:px-10 px-3 md:pb-20 pb-10 ">

                <h1 className="mb-4 text-[white] font-semibold md:leading-10 pt-16 pb-5 tracking-normal">
                  <span className="font-[700] md:block capitalize hidden md:text-[40px] text-[26px] md:leading-[70px] bg-opacity-20 bg-black" > Promoting Youth Health for a better tomorrow.

                  </span>

                  <span className="text-white font-[700] md:text-[66px] block md:hidden text-center text-[26px] bg-opacity-20 bg-black" > Promoting Youth Health for a better tomorrow.
                  </span>
                </h1>
                

                <p className="md:max-w-2xl pr-[30px] md:text-[20px] md:block hidden text-[16px]  mt-3 font-normal text-white mb-8 text-base break-all bg-opacity-20 bg-black">
                  Youth Care Project excels in understanding and <br />aiding  disadvantaged youth through practical <br />support.
                </p>

                <p className="text-[16px] block md:hidden mt-3 font-normal text-black mb-8 bg-opacity-20 bg-black">
                  Youth Care Project excels in understanding and aiding  disadvantaged youth through practical support.
                </p>

                <div className="flex  ">
                  <NavLink to="/donate" className="">
                    <button
                      type="submit"
                      className="py-2.5 md:px-5  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                    >
                      Donate Now
                    </button>
                  </NavLink>

                </div>
              </div>

              <div className="hidden lg:block md:mt-16">
                {/* <img src="/images/logo33.png" alt="" className="py-4"/> */}

              </div>

            </div>
          </div>
        
        </div>

        <div>
          <div className="">
            <div
              style={{
                backgroundImage: `url(${image2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: 'center center'

              }}
              className=" md:py-20 bg-contain lg:flex lg:justify-between "
            >

              {/* <div className="md:hidden block pt-16">
                <img src="/images/back2.png" alt="location" />
              </div> */}
              <div className="md:mr-auto place-self-center col-span-7 pt-16  md:px-10 px-3 md:pb-20 pb-10">

                <h1 className="mb-4 text-[white] font-semibold md:leading-10 pt-16 pb-5 tracking-normal">
                  <span className="font-[700] md:block capitalize hidden md:text-[40px] text-[26px] md:leading-[70px]" > Promoting Youth Health for a better tomorrow.

                  </span>

                  <span className="text-white font-[700] md:text-[66px] block md:hidden text-center text-[26px]" > Promoting Youth Health for a better tomorrow.
                  </span>
                </h1>

                <p className="md:max-w-2xl pr-[30px] md:text-[20px] md:block hidden text-[16px]  mt-3 font-normal text-white mb-8 text-base break-all">
                  Youth Care Project excels in understanding and <br />aiding  disadvantaged youth through practical <br />support.
                </p>

                <p className="text-[16px] block md:hidden mt-3 font-normal text-black mb-8">
                  Youth Care Project excels in understanding and aiding  disadvantaged youth through practical support.
                </p>

                <div className="flex  ">
                  <NavLink to="/donate" className="">
                    <button
                      type="submit"
                      className="py-2.5 md:px-5  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                    >
                      Donate Now
                    </button>
                  </NavLink>

                </div>
              </div>

              <div className="hidden lg:block md:mt-16">
                {/* <img src="/images/logo33.png" alt="" className="py-4"/> */}

              </div>

            </div>
          </div>

        </div>
        <div>
          <div className="">
            <div
              style={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: 'center center'

              }}
              className=" md:py-20 bg-contain lg:flex lg:justify-between "
            >

              {/* <div className="md:hidden block pt-16">
                <img src="/images/back2.png" alt="location" />
              </div> */}
              <div className="md:mr-auto place-self-center col-span-7 pt-16  md:px-10 px-3 md:pb-20 pb-10">

                <h1 className="mb-4 text-[white] font-semibold md:leading-10 pt-16 pb-5 tracking-normal">
                  <span className="font-[700] md:block capitalize hidden md:text-[40px] text-[26px] md:leading-[70px]" > Promoting Youth Health for a better tomorrow.

                  </span>

                  <span className="text-white font-[700] md:text-[66px] block md:hidden text-center text-[26px]" > Promoting Youth Health for a better tomorrow.
                  </span>
                </h1>

                <p className="md:max-w-2xl pr-[30px] md:text-[20px] md:block hidden text-[16px]  mt-3 font-normal text-white mb-8 text-base break-all">
                  Youth Care Project excels in understanding and <br />aiding  disadvantaged youth through practical <br />support.
                </p>

                <p className="text-[16px] block md:hidden mt-3 font-normal text-black mb-8">
                  Youth Care Project excels in understanding and aiding  disadvantaged youth through practical support.
                </p>

                <div className="flex  ">
                  <NavLink to="/donate" className="">
                    <button
                      type="submit"
                      className="py-2.5 md:px-5  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                    >
                      Donate Now
                    </button>
                  </NavLink>

                </div>
              </div>

              <div className="hidden lg:block md:mt-16">
                {/* <img src="/images/logo33.png" alt="" className="py-4"/> */}

              </div>

            </div>
          </div>

        </div>
      </Carousel2>
      </section>



      <section className="md:px-20 px-5 bg-[#F3F5F7]">
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 md:gap-52 gap-20  ">
          <div className="flex flex-col">
            <span className="text-black text-[34px] font-[700]">Story About <br /><span className="text-[#FA0000]">What we do.</span></span>
            <span className="font-[400] text-[14px] text-[#949494] mt-[14px] mb-[38px]">Youth Care Project (YCP) is a non- governmental, non- profit organization established to address the health and development needs of Nigerian Youths in a holistic but sustainable manner. </span>
            <NavLink to="/about-us" className="">
              <button
                type="button"
                className="py-2.5 md:px-5  px-2 text-sm font-medium text-white bg-[black] border border-[black] "
              >
                Learn More
              </button>
            </NavLink>
          </div>

          <div className="grid md:grid-cols-2 gap-5 grid-cols-1">


            <div className="flex flex-col ">
              <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.HEALTH} /></span>
              <span className="font-bold flex justify-center">Health Education</span>
              <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Through this programme, a forum has been created for youth to come together to discuss issues about their health, relationships, dating, abstinence, gender issues etc.</span>

            </div>




            <div className="flex flex-col">
              <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.TRAINING} /></span>
              <span className="font-bold flex justify-center">Centre Library</span>
              <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> The Centre library has been equipped with books, furniture, tables, fan and other facilities
                During the period under review, the centre launched a community outreach programme to improve the health of the communities we serve.</span>
            </div>


            <div className="flex flex-col md:mt-10">
              <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.SERVICE} /></span>
              <span className="font-bold flex justify-center">Health Service</span>
              <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> The youth friendly clinic was fully equipped with all the necessary facilities and drugs.  Medical personnel were also deployed to the clinic.</span>

            </div>

            <div className="flex flex-col md:mt-10">
              <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
              <span className="font-bold flex justify-center"> Leadership Training</span>
              <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> A two – day workshop was organized to train young people on leadership and Development.72 youths participated in the 2- day workshop</span>

            </div>

          </div>

        </div>
      </section>





      <section
        style={{
          backgroundImage: `url(${hands})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'center center'

        }}

        className="pb-10 mb-10"
      >
        <div className="flex justify-end pt-10">
          <SvgElement type={icontypesEnum.MISSION} />
        </div>

        <div className="flex justify-start">
          <SvgElement type={icontypesEnum.VISION} />
        </div>

      </section>


      <section className=" bg-[#F3F5F7] pt-20 mb-10 md:px-20 px-5">
        <div className="pb-5">
          <div className=" md:text-[48px] text-[20px] font-[700] md:text-left text-center pb-10"> Our  <span className="text-[#FA0000]">Goals</span></div>
          <span className="text-[#949494] font-[400]">YCP was founded for the following goals:</span>
        </div>


        <div className="grid md:grid-cols-4 grid-cols-1 gap-4 pb-10">
          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To improve health outcomes and optimal development for all young people in their diversities.</span><br />
            </div>
          </div>


          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To ensure young people's full and effective participation and equal opportunities for leadership at all levels of decision-making in political, economic and public life. </span><br />
            </div>
          </div>


          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To promote gender equality and end all forms of discrimination, violence and rights violations against all girls and young women everywhere. </span><br />
            </div>
          </div>


          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To substantially increase the number of youths who have relevant skills, including technical and vocational skills, for employment, decent jobs and entrepreneurship.</span><br />
            </div>
          </div>
        </div>
      </section>


      <section className="  pt-20 mb-10 md:px-20 px-5">
        <div className="pb-5">
          <div className=" md:text-[48px] text-[20px] font-[700] md:text-left text-center pb-10"> Our Specific<br /> <span className="text-[#FA0000]">Objective</span></div>
          <span className="text-[#949494] font-[400]">YCP was founded for the following specific objectives:</span>
        </div>


        <div className="grid md:grid-cols-4 grid-cols-1 gap-4 pb-10">
          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To contribute to improved health outcomes and optimal development for all young people.</span><br />
            </div>
          </div>


          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To provide young people with resource centre and encourage them channel their energies into wholesome activities that would enhance their self–development.</span><br />
            </div>
          </div>


          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To improve the level and intensity of young people’s meaningful engagement, participation, and involvement in the development and implementation of all policies and programmes relating to their health and development at all levels.</span><br />
            </div>
          </div>


          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To strengthen the capacity of parents, households and the community system to provide the appropriate supportive environment and care to adolescents and young people’s health and development. </span><br />
            </div>
          </div>



          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To empower youth leadership and involvement across family, community, and global levels, tackling gender disparities, including violence, and promoting health and development equity.</span>
            </div>
          </div>

          <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To strengthen partnerships, networking and collaborations between state and non-state actors at the community, state, national and international levels to enhance implementation of young people’s health and development agenda. </span>
            </div>
          </div>


          {/* <div className="flex gap-2 mt-10">
            <span className=" bg-[#FA0000] text-[#FA0000]">i</span>
            <div>
              <span className="text-[14px] font-[300]">To inculcate in and prepare young people for effective and people –oriented leadership</span><br />
            </div>
          </div> */}
        </div>
      </section>



      <section className="md:px-20 px-5 bg-[#F3F5F7]">
        <div className="grid grid-cols-2">
          <div className="flex flex-col md:mt-20">
            <span className="text-black text-[34px] font-[700]">Our Core <span className="text-[#FA0000]">Values</span></span>
          </div>
        </div>


        <div className="grid md:grid-cols-3 gap-5 grid-cols-1 my-10">
          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.HEALTH} /></span>
            <span className="font-bold flex justify-center">Accountability </span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center">Youth Care Project holds staff responsible for their actions in the spirit of openness and transparency.</span>
          </div>

          <div className="flex flex-col">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.TRAINING} /></span>
            <span className="font-bold flex justify-center">Integrity</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center">Staff and the organization undertake work to the highest standards possible, ensure the confidentiality of personal information and adhere to ethical principles. </span>
          </div>


          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.SERVICE} /></span>
            <span className="font-bold flex justify-center">Mutual Respect</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> The organization fosters an environment in which staff, project beneficiaries and partners feel safe, show regard for each other's feelings, opinions, race, gender, culture and religious beliefs.</span>

          </div>

          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Diligence</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> In Youth Care Project, there exist a commitment to accomplish what is undertaken, thereby ensuring that any piece of work is carried out within scheduled time and of excellent quality.</span>

          </div>



          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Dynamism</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> In the organization, new ideas are generated and ideas from other parts of the world are adapted to suit Nigeria context and enhance quality of services delivered by the organization.</span>

          </div>


          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Creativity</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Innovative and creative ways of delivering the Organization’s mandate are continually deployed.</span>

          </div>


          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Professionalism</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Staff have interest and desire to do job well and consider high level of professionalism as crucial in all tasks. </span>

          </div>



        </div>


      </section>



      <section className="bg-[#F3F5F7] pt-10 md:px-20 px-2 pb-16 body-font font-poppins">
        <div
          className="mx-auto "
        >

          <div className=" md:text-[48px] text-[20px] font-[700] px-10"> Some of our <span className="text-[#FA0000]">Projects </span></div>
        </div>
        <div className="flex space-x-2 justify-start pr-3">
          <NavLink to={'/project'}
            className="py-2.5 md:px-5 mx-10 mb-2  px-2 text-sm font-medium text-white bg-[black] rounded-lg border border-[black] "
          >
            View All Projects
          </NavLink>
        </div>
        <div id="slider" className='max-w-screen-xl px-2 lg:px-2 pt-10 pb-8 mx-auto lg:gap-20'>
          <Carousel cols={3} rows={1} gap={30} loop hideArrow={false}>
            <Carousel.Item>
              <NavLink to={'/clinic-at-your-door-step'}>
                <div className="bg-white max-w-sm pb-10 border m-2 cursor-pointer mt-5 px-10 py-6 rounded-lg shadow-lg">
                  <div className="flex justify-center">
                    <img src="/images/cad2.jpeg" alt="location" style={{ width: "100%" }} />
                  </div>
                  <div className="flex justify-between pt-5 pb-3">
                    <div className="flex flex-col">
                      <span className=" text-[16px] font-[700] pb-2">CLINIC AT YOUR DOORSTEP </span>
                      <div className="mb-2 mt-2">
                        <span
                          className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                        >
                          Read More
                        </span>

                      </div>

                    </div>
                    {/* <div className="flex">
                      <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                      <span>20</span>
                    </div> */}

                  </div>

                  {/* <div className="bg-[black] rounded-md py-2 px-2">
                    <span
                      className=" md:px-1 px-1 text-sm font-medium text-white  "
                    >This project/event aims at promoting health as well as narrowing the gaps and reducing inequalities.
                    </span>
                  </div> */}

                </div>
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to={'/skill-acquisition-program'}>
                <div className="bg-white max-w-sm pb-10 border m-2 cursor-pointer mt-5 px-10 py-6 rounded-lg shadow-lg">
                  <div className="flex justify-center">
                    <img src="/images/skillAcquisition/sa5.JPG" alt="location" style={{ width: "100%" }} />
                  </div>
                  <div className="flex justify-between pt-5 pb-3">
                    <div className="flex flex-col">
                      <span className=" text-[16px] font-[700] pb-2">SKILLS ACQUISITION PROGRAM </span>
                      <div className="mb-2 mt-2">
                        <span
                          className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                        >
                          Read More
                        </span>

                      </div>

                    </div>
                    {/* <div className="flex">
                      <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                      <span>20</span>
                    </div> */}

                  </div>

                  {/* <div className="bg-[black] rounded-md py-2 px-2">
                    <span
                      className=" md:px-1 px-1 text-sm font-medium text-white  "
                    >At the vocational training and skill acquisition center
                    </span>
                  </div> */}

                </div>
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to={'/talent-hunt'}>
                <div className="bg-white max-w-sm pb-10 border m-2 cursor-pointer mt-5 px-10 py-6 rounded-lg shadow-lg">
                  <div className="flex justify-center">
                    <img src="/images/talentHunt/th1.JPG" alt="location" style={{ width: "100%" }} />
                  </div>
                  <div className="flex justify-between pt-5 pb-3">
                    <div className="flex flex-col">
                      <span className=" text-[16px] font-[700] pb-2">TALENT HUNT</span>
                      <div className="mb-2 mt-2">
                        <span
                          className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                        >
                          Read more
                        </span>

                      </div>

                    </div>
                    {/* <div className="flex">
                      <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                      <span>20</span>
                    </div> */}

                  </div>

                  {/* <div className="bg-[black] rounded-md py-2 px-2">
                    <span
                      className=" md:px-1 px-1 text-sm font-medium text-white  "
                    >The center also work with young people to harness their talents and potentials in sports particularly football. 
                    </span>
                  </div> */}

                </div>
              </NavLink>
            </Carousel.Item>

            <Carousel.Item>
              <NavLink to={'/citizenship-and-leadership-training'}>
                <div className="bg-white max-w-sm pb-10 border m-2 cursor-pointer mt-5 px-10 py-6 rounded-lg shadow-lg">
                  <div className="flex justify-center">
                    <img src="/images/anualMeeting/a3.jpg" alt="location" style={{ width: "100%" }} />
                  </div>
                  <div className="flex justify-between pt-5 pb-3">
                    <div className="flex flex-col">
                      <span className=" text-[16px] font-[700] pb-2">CITIZENSHIP AND LEADERSHIP TRAINING</span>
                      <div className="mb-2 mt-2">
                        <span
                          className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                        >
                          Read More
                        </span>

                      </div>

                    </div>
                  </div>

                </div>
              </NavLink>
            </Carousel.Item>


            {/* <Carousel.Item>
              <NavLink to={'/community-outreach-activities'}>
                <div className="bg-white max-w-sm pb-10 border m-2 cursor-pointer mt-5 px-10 py-6 rounded-lg shadow-lg">
                  <div className="flex justify-center">
                    <img src="/images/clinic.webp" alt="location" style={{ width: "100%" }} />
                  </div>
                  <div className="flex justify-between pt-5 pb-3">
                    <div className="flex flex-col">
                      <span className=" text-[16px] font-[700] pb-2">COMMUNITY OUTREACH ACTIVITIES</span>
                      <div className="mb-2 mt-2">
                        <span
                          className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                        >
                          Read More
                        </span>

                      </div>

                    </div>
                  </div>

                </div>
              </NavLink>
            </Carousel.Item> */}


            <Carousel.Item>
              <NavLink to={'/capacity-building'}>
                <div className="bg-white max-w-sm pb-10 border m-2 cursor-pointer mt-5 px-10 py-6 rounded-lg shadow-lg">
                  <div className="flex justify-center">
                    <img src="/images/capacityBuilding/cb4.JPG" alt="location" style={{ width: "100%" }} />
                  </div>
                  <div className="flex justify-between pt-5 pb-3">
                    <div className="flex flex-col">
                      <span className=" text-[16px] font-[700] pb-2"> CAPACITY BUILDING FOR STAFF</span>
                      <div className="mb-2 mt-2">
                        <span
                          className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                        >
                          Read More
                        </span>

                      </div>

                    </div>
                    {/* <div className="flex">
                      <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                      <span>20</span>
                    </div> */}

                  </div>

                  {/* <div className="bg-[black] rounded-md py-2 px-2">
                    <span
                      className=" md:px-1 px-1 text-sm font-medium text-white  "
                    >The objective of the workshop was to improve the knowledge, skills and competencies of workers towards better performance
                    </span>
                  </div> */}

                </div>
              </NavLink>
            </Carousel.Item>


          </Carousel>
        </div>


      </section>


      <section className="md:px-20 px-5 ">

        <div className="grid grid-cols-2">
          <div className="flex flex-col md:mt-20">
            <span className="text-black text-[34px] font-[700]">Our Program <span className="text-[#FA0000]">Areas</span></span>
            {/* <span className="font-[400] text-[14px] text-[#949494] mt-[14px] mb-[38px]">Youth Care Project (YCP) is a non- governmental, non- profit organization established to address the health and development needs of Nigerian Youths in a holistic but sustainable manner. </span> */}

          </div>
        </div>




        <div className="grid md:grid-cols-3 gap-5 grid-cols-1 my-10">
          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.HEALTH} /></span>
            <span className="font-bold flex justify-center">Health </span>
            <span className="text-[10px] text-[#949494] font-bold flex justify-center">communicable and non-communicable diseases</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Addressing common non-communicable diseases (e.g., prevention of breast and cervical cancer, high blood pressure, diabetes, malaria etc.) and communicable diseases/infections such as; (HIV/AIDS, Sexually Transmitted Infections prevention etc.).</span>
          </div>

          <div className="flex flex-col">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.TRAINING} /></span>
            <span className="font-bold flex justify-center">Mental Health, Substance Use and Addictions</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Mental health promotion, preventing and managing disorders, including suicidality and eating disorders; prevention and control of substance use, misuse and abuse.  </span>
          </div>


          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.SERVICE} /></span>
            <span className="font-bold flex justify-center">Sexual and Reproductive Health and Rights</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Address pubertal concerns, including menstrual hygiene, sexuality education, risky behavior, teenage pregnancies, respectful maternal health services, sexual violence, genital mutilation, early marriages, trafficking, and other reproductive health rights violations.</span>

          </div>

          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Leadership and Governance</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Despite the passage of the "Not Too Young to Rule Bill" and presidential assent, Nigeria's political environment poses challenges for youth engagement. This program focuses on mentoring and developing leadership skills for effective participation in health and development issues.</span>

          </div>



          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Poverty Reduction and Economic Empowerment</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Nigeria grapples with pervasive poverty, particularly among its energetic youth, leading to high unemployment and associated social issues. This program aims to provide livelihood empowerment and mentorship, enabling youth to secure gainful employment and lead fulfilling lives.</span>

          </div>


          <div className="flex flex-col ">
            <span className="mb-6 flex justify-center"> <SvgElement type={icontypesEnum.PERSON} /></span>
            <span className="font-bold flex justify-center">Digital Technology and Innovation</span>
            <span className="text-[10px] text-[#949494] mt-2 flex justify-center"> Utilize digital technologies for youth health and development while addressing cyberbullying, sexting, and other harmful online behaviors, as well as gaming addiction and problematic digital technology use.</span>

          </div>



        </div>


      </section>



      <section
        style={{
          backgroundImage: `url(${volunteer})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'center center'

        }}

        className="pb-10 mb-10 pt-20"
      >
        <div className="flex justify-center text-white">
          <span className="md:text-[47px] text-[30px] font-[700] pb-5">Become a Volunteer</span>
        </div>

        <div className="flex justify-center">
          <span className="text-white text-[20px] fornt-[400] pb-20">Join us in making the world a better place one step at a time</span>
        </div>

        <div className="flex justify-center">
          <NavLink to={'/donate'}>
            <span
              className="py-2.5 md:px-5  px-2 text-sm font-medium text-white bg-[#FA0000] "
            >
              Donate Now
            </span>
          </NavLink>

        </div>


      </section>

      <section className="md:px-20 px-2">
        <div className="pt-10 px-6 md:text-[48px] text-[24px] font-[700] md:pb-14 pb-2">
          <span>Our Gallery</span>
        </div>

        <div id="slider" className=' md:pt-10 pt-1 pb-8 mx-auto lg:gap-20'>
          <Carousel cols={3} rows={1} gap={30} loop hideArrow={false}>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g1.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g5.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g3.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g4.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g5.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g1.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white md:max-w-sm pb-10  mt-5 md:px-10 px-2 py-6 rounded-lg shadow-lg">
                <div className="flex justify-center">
                  <img src="/images/g3.png" alt="location" style={{ width: "90%" }} />
                </div>
              </div>
            </Carousel.Item>

          </Carousel>
        </div>

      </section>





      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default Home;
