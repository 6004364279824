import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import image from "../../../img/cb4.jpg";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../../Faq";
import WFooter from "../../WFooter";
import Footer from "../../Footer";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";


function AboutDetails() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-20 pt-[92px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-52 md:pb-8  flex justify-start "
          >
            <div className="py-20">
              <div className="text-[white] max-w-fit md:ml-44 pl-2 pr-20 flex justify-start mb-4 bg-black bg-opacity-80">
                <span className="font-[700] md:text-[20px] text-[20px] py-4" > Project Details</span>
              </div>

              <div className="text-[white] md:ml-44 pl-2 pr-20 flex justify-start bg-[#9F0000BA] bg-opacity-80">
                <span className="font-[700] md:text-[48px] text-[20px] py-4" > Capacity Building</span>
              </div>
            </div>
          </div>

          {/* <div className="bg-black py-4">
            <div className="grid md:grid-cols-2 md:px-20 px-3 gap-10 pt-10">
              <div className="text-white">
                Location : Pako Field, Pako B/Stp, Dopemu Lagos.
              </div>

              <div className="text-white">
                Topic : Talent Hunt
              </div>

              <div className="text-white">
                Objective : The center also work with young people to harness their talents and potentials in sports particularly football.
              </div>
            </div>
          </div> */}
        </div>
      </section>


      <section>
        <div className="pt-10 md:px-20 px-4 pb-5">
          <div className="text-[white] max-w-fit pl-2 pr-20 flex justify-start mb-10 bg-[#FA0000] ">
            <span className="font-[700] md:text-[20px] text-[20px] py-2" > Project Overview</span>
          </div>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">Capacity building for staff refers to the process of enhancing the knowledge, skills, abilities, and resources of individuals within an organization or team. It aims to improve their performance, effectiveness, and overall capacity to carry out their roles and responsibilities more efficiently.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5"> Here are some key steps and strategies for effective capacity building for staff:</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5"> 1.	Assess Needs: Start by conducting a thorough assessment of the current skills, knowledge gaps, and areas for improvement among your staff. This can be done through surveys, interviews, performance evaluations, or self-assessment tools. Identify the specific areas where capacity building is required.</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5"> 2.	Set Clear Objectives: Define clear objectives and goals for capacity building. Determine what specific skills or knowledge you want your staff to acquire or enhance. Ensure that these objectives align with the overall mission and goals of the organization.</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">3.	Develop a Training Plan: Based on the identified needs and objectives, develop a comprehensive training plan. This plan should outline the specific training programs, workshops, seminars, or courses that will be provided to address the identified gaps. Consider both internal and external training resources.</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5">4.	Provide Training Opportunities: Implement the training plan by providing staff with access to relevant training opportunities. This can include in-house training sessions, external workshops or conferences, online courses, mentoring programs, or guest speakers. Consider the availability of resources, budget, and the learning preferences of your staff.</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5">5.	Encourage Continuous Learning: Promote a culture of continuous learning within the organization. Encourage staff to seek out additional learning opportunities, read relevant materials, attend conferences or webinars, and engage in professional development activities. Provide resources and support for self-directed learning.</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5">6.	Support Knowledge Sharing: Create platforms or mechanisms for staff to share their knowledge and experiences with one another. This can include regular team meetings, knowledge-sharing sessions, mentorship programs, or online collaboration tools. Encourage staff to learn from each other and foster a culture of collaboration.</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">7.	Provide Coaching and Mentoring: Offer coaching and mentoring programs to support staff in their professional growth. Assign experienced staff members as mentors to provide guidance, feedback, and support to less experienced colleagues. This can help accelerate learning and development.</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">8.	Evaluate and Assess Progress: Regularly evaluate the effectiveness of the capacity building initiatives. Collect feedback from staff, assess their progress, and measure the impact of the training on their performance. Use this information to make necessary adjustments and improvements to the capacity building programs.</p>
          {/* <p className="text-[#666666] text-[17px] font-[300] pb-5">
            There is a huge misconception of information on various health issues among community members in Nigeria.  Different studies had shown lack of knowledge, inadequate and insincere information on different health issues. Most health issues like Hypertension, Diabetes and Malaria are often misunderstood with regards to causes, prevention and treatment.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            A lot of patients in Nigeria who have been diagnosed of hypertension and diabetes are not compliant with their drugs usage, while some have no access to good drugs and this often results in complications and possible death.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            To reduce the death rates and possible complications of those ailments mentioned, YCP launched a Community Health Outreach program Tagged “CLINIC AT YOUR DOOR STEP” YCP in collaboration with the respective Local Governments and their Community Development Associations in three states of Nigeria namely, Ogun, Ondo and Lagos State.  The project is to educate and create awareness among community members about the dangers of most of these ailments threatening the health of Nigerians and Africa as a whole and possible solution for their prevention and cure.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            The health services rendered by the Clinic at your door steps program include: health  activities, health screening for hypertension, malaria and diabetes; which according to World Health Organization (WHO), reduces the life expectancy of Nigerian people. Other services include Treatment of minor ailments such as malaria, diarrhea and upper respiratory tract infection and referral to primary health centers. These services are carried out on drug revolving basis  at a cost  less than a dollar per client.
          </p> */}

        </div>
        <div className="grid md:grid-cols-4 py-10 gap-2 px-10">
          <span>  <img src="/images/capacityBuilding/cb3.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb4.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb5.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb2.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb1.JPG" alt="location" style={{}} /></span>
        </div>
      </section>

      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default AboutDetails;
