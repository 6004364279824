import React from 'react'
import Faq from '../Faq'
import Navbar from '../Navbar'
import Footer from "../Footer";

function FaqPage() {
  return (
    <>
    <Navbar/>
    <div className='pt-20' >
    <Faq/>
    <Footer/>
    </div>

    </>
  )
}

export default FaqPage