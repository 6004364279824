import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../Navbar";
import image from "../../img/about.png";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../Faq";
import WFooter from "../WFooter";
import Footer from "../Footer";
import { SvgElement, icontypesEnum } from "../assets/svgElement";


function About() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-20 pt-[72px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-28 md:pb-8 bg-contain lg:flex lg:flex-col "
          >
            <div className="md:mr-auto place-self-center col-span-7  md:px-10 px-3 md:pb-10 pb-10">
              <span className=" text-[20px] font-[500] text-white">About</span>
              <h1 className="mb-4 text-[#ffffff] font-semibold md:leading-10 pt-16 pb-5 tracking-normal">
                <span className="font-[700] md:text-[66px] text-[26px] md:leading-[70px]" > We are always<br /> Spreading <span className="text-[red]">Smiles.</span></span>
              </h1>
            </div>

            <div className="hidden lg:block mt-1 mr-3">
              <svg width="796" height="135" viewBox="0 0 796 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 35611">
                  <g id="Rectangle 6427" filter="url(#filter0_d_54_1928)">
                    <rect x="18" y="14" width="760" height="99" rx="15" fill="white" />
                  </g>
                  <text id="Years Experience" fill="#949494" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-family="Poppins" font-size="16" letter-spacing="0em"><tspan x="103" y="86.6">Years Experience</tspan></text>
                  <text id="Lives Touched" fill="#949494" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-family="Poppins" font-size="16" letter-spacing="0em"><tspan x="325" y="86.6">Lives Touched</tspan></text>
                  <text id="Locations Reached" fill="#949494" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-family="Poppins" font-size="16" letter-spacing="0em"><tspan x="548" y="89.6">Locations Reached</tspan></text>
                  <text id="20+" fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-family="Poppins" font-size="36" font-weight="600" letter-spacing="0em"><tspan x="131" y="60.6">20+</tspan></text>
                  <text id="450+" fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-family="Poppins" font-size="36" font-weight="600" letter-spacing="0em"><tspan x="337" y="60.6">450+</tspan></text>
                  <text id="3000+" fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-family="Poppins" font-size="36" font-weight="600" letter-spacing="0em"><tspan x="557" y="63.6">3000+</tspan></text>
                </g>
                <defs>
                  <filter id="filter0_d_54_1928" x="0" y="0" width="796" height="135" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="5" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_54_1928" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="6.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.979167 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_54_1928" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_54_1928" result="shape" />
                  </filter>
                </defs>
              </svg>
            </div>

          </div>
        </div>

      </section>


      <section>
        <div className="grid md:grid-cols-2 md:py-20 py-4 md:gap-20 gap-5 md:px-20 px-4">
          <div className="flex flex-col pt-20">
            <span className="text-[40px] font-[700] md:text-left text-center">The best <br/> Health NGO</span>
            <span className="text-[16px] font-[300] pt-4">Youth Care Project (YCP) is a non- governmental, non- profit organization established to address the health and development needs of Nigerian Youths in a holistic but sustainable manner. Established in June 1998, YCP works to improve the health of young people facilitate their balanced development and build their capacity to live responsible and productive lives in an increasingly frustrating and hostile environment.</span>
          </div>

          <div>
          <img src="/images/ngo.png" alt="location" style={{ width: "90%" }} />
          </div>

        </div>
      </section>


      <section>
        <div className="grid md:grid-cols-2 md:py-20 pb-10 md:gap-20 gap-5 md:px-20 px-4">

        <div className="hidden md:block">
          <img src="/images/ngo2.png" alt="location" style={{ width: "90%" }} />
          </div>

          <div className="flex flex-col pt-20">
            <span className="text-[40px] font-[700] md:text-left text-center">A Well Connected  <br/> Organisation</span>
            <span className="text-[16px] font-[300] pt-4">YCP has its corporate headquarters in Lagos but runs and maintains an outreach called Youth Development Centers in partnership with other stakeholders. Currently, YCP has established such center in two Local Government Areas (LGAs) of Ondo, State, with a vision to expanding to other states of the federation.</span>
          </div>

          <div className="md:hidden block">
          <img src="/images/ngo2.png" alt="location" style={{ width: "90%" }} />
          </div>
        </div>
      </section>






      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default About;
