import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../Navbar";
import image from "../../img/donate.png";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../Faq";
import WFooter from "../WFooter";
import Footer from "../Footer";
import { SvgElement, icontypesEnum } from "../assets/svgElement";


function Donate() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-10 mb-10 pt-[72px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-28 md:pb-8 relative"
          >
            <div className="md:px-10 px-3 md:pb-10 pb-10">
              <span className=" text-[20px] font-[500] text-[#ffffff]">Donations</span>
              <h1 className="mb-4 text-[#ffffff] font-semibold md:leading-10 pt-16 pb-5 tracking-normal">
                <span className="font-[700] md:text-[66px] text-[26px] md:leading-[70px]" > We are all about<br /> Making Lives<br /> <span className="text-[#FA0000]">Better</span></span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className=" min-h-full">
        <div className=" grid md:grid-cols-3 w-full  ">
          <span className="hidden md:block"></span>
          <div className="flex-auto px-4 py-10 pt-3  border-8 rounded-lg border-gray-300 m-6 bg-white">
            <div className="lg:px-4">
              <h1 className="text-[#171717] md:text-3xl text-2xl font-semibold">Get Involved Now</h1>
              <p className="text-xs text-[#666666] my-5">We are ready to help you in any way possible fo a smoother and easier learning process.</p>
            </div>
            <form>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 lg:px-4">
                  <div className="relative w-full mb-6">
                    <label
                      className="block text-[#666666] text-sm font-medium mb-2"
                      htmlFor="grid-password"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      className="  border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 lg:px-4">
                  <div className="relative w-full mb-6">
                    <label
                      className="block text-[#666666] text-sm font-medium mb-2"
                      htmlFor="grid-password"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      className="  border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 lg:px-4">
                  <div className="mb-6 ">
                    <label className="block mb-2 text-sm font-semibold text-[#666666]">
                      Phone Number *
                    </label>
                    <input
                      type="number"
                      className=" border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3"
                      placeholder=""
                      name="l_name"
                      required

                    />
                  </div>
                </div>


                <div className="w-full lg:w-6/12 lg:px-4">
                <div className="mb-6 ">
                    <label className="block mb-2 text-sm font-semibold text-[#666666]">
                      Email address *
                    </label>
                    <input
                      type="email"
                      className=" border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3"
                      placeholder=""
                      name="l_name"
                      required

                    />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 lg:px-4">
                  <div className="mb-6 ">

                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-[#666666] ">Message</label>
                    <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-[#333333]  rounded-md border border-[#FA0000]" placeholder=""></textarea>

                  </div>
                </div>

                <div className="w-full lg:w-12/12 lg:px-4 pb-3">
                <p className=" text-gray-400 text-[10px]">Kindly pay to our account: xxxxxxxxxxxxxxx</p>
                </div>
                

                <div className="lg:pl-4">
                  <button
                    type="submit"
                    className="py-2.5 px-6  text-sm font-medium text-white bg-[#FA0000] rounded-lg "
                  >
                    Donate
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>



      <Footer />








      {/* <!-- End block --> */}

    </>
  );
}

export default Donate;
