import React from 'react'
import { SvgElement, icontypesEnum } from './assets/svgElement';
import { Link, NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className='bg-black pt-4 text-white'>
        <div className="max-w-screen-xl px-8 lg:px-14 pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-10  ">
          <div className='grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-8'>
            <div className='col-span-2 mb-6'>
              <div className=''>
               

              </div>


              <form>
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">

                  <input type="search" id="default-search" className="block w-[80%] p-2.5 pl-2 text-sm text-gray-900 placeholder:text-sm border border-[#FA0000] rounded-lg" placeholder="Your Email Here..." required />
                  <button type="submit" className="text-white absolute right-0 bottom-0 border border-[#FA0000] bg-[#FA0000] hover:bg-[#05401C] font-medium text-sm px-4 py-2.5 rounded-r-lg">Subscribe</button>
                </div>
              </form>
            </div>

            <div className='col-span-2'>

            </div>

           

            <div className=' col-span-2'>
              <h4 className='text-[#FA0000] font-medium mb-2'>About Us</h4>
              {/* <h3>Branding</h3> */}
              <Link to='/'> <h3>Projects</h3></Link>
              <Link to='/about-us'> <h3>About us</h3></Link>
              {/* <h3>Affiliates</h3> */}
              {/* <h3>Careers</h3> */}
            </div>
            <div className=' col-span-2'>
              <h4 className='text-[#FA0000] font-medium mb-2'>Help and Support</h4>
              
              <Link to='/contact-us'>  <h3>Contact Us</h3></Link>
              <Link to='#'><h3>Privacy & Terms</h3></Link>
              {/* <h3>Safety information</h3> */}
            </div>
            

          </div>

          <div className='flex flex-col lg:flex-row lg:justify-between mt-16' >
            <div className='md:flex flex md:flex-none md:space-x-12 text-[#FA0000]'>
              <p>@copyright2023</p>
              <div className='flex lg:space-x-8 space-x-3 pl-4'>
                <p>Help</p>
                <p>Privacy</p>
                <p>Terms</p>
              </div>
            </div>
            <div className='flex space-x-10 mt-4 lg:mt-0'>
              <div className="flex space-x-5 mt-1">
                <NavLink to="#" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.TWITTER} />
                </NavLink>
                <NavLink to="#" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.FACEBOOK} />
                </NavLink>
                <NavLink to="#" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.INSTAGRAM} />
                </NavLink>
                <NavLink to="#" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.LINKEDIN} />
                </NavLink>
              </div>
              <div className='text-[#FA0000]'>Us-English</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer