import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import image from "../../../img/sa.jpeg";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../../Faq";
import WFooter from "../../WFooter";
import Footer from "../../Footer";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";


function AboutDetails() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-20 pt-[72px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-28 md:pb-8  flex justify-start "
          >
            <div className="py-20">
              <div className="text-[white] max-w-fit md:ml-44 pl-2 pr-20 flex justify-start mb-4 bg-black bg-opacity-80">
                <span className="font-[700] md:text-[20px] text-[20px] py-4" > Project Details</span>
              </div>

              <div className="text-[white] md:ml-44 pl-2 pr-20 flex justify-start bg-[#9F0000BA] bg-opacity-80">
                <span className="font-[700] md:text-[48px] text-[20px] py-4" > Skill Acquisition Program</span>
              </div>
            </div>
          </div>

          {/* <div className="bg-black py-4">
            <div className="grid md:grid-cols-2 md:px-20 px-3 gap-10 pt-10">
              <div className="text-white">
                Location : Pako Field, Pako B/Stp, Dopemu Lagos.
              </div>

              <div className="text-white">
                Topic : THE VOCATIONAL TRAINING AND SKILLS ACQUISITION DEPARTMENT.
              </div>

              <div className="text-white">
                Objective : The vocational training and skill acquisition center is also meant to solve the problem of unemployment among young people in the state as they are trained in one skill or the other to become productive and responsible members of their communities.
              </div>
            </div>
          </div> */}
        </div>
      </section>


      <section>
        <div className="pt-10 md:px-20 px-4 pb-5">
          <div className="text-[white] max-w-fit pl-2 pr-20 flex justify-start mb-10 bg-[#FA0000] ">
            <span className="font-[700] md:text-[20px] text-[20px] py-2" > Project Overview</span>
          </div>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">During the period under review, YCP established and equipped three vocational training and skills acquisition department at the center for young people in the Local Government Area.  This is in realization of the fact that young people need specific skills that prepares them to thrive in a fast changing labour market and to be self-employed and self –reliant.  At the center, young people are trained in a various trades such as fashion design and tailoring, GSM handset repair, tie and die (design of local fabric), catering and cookery.
</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
          The vocational training and skill acquisition center is also meant to solve the problem of unemployment among young people in the state as they are trained in one skill or the other to become productive and responsible members of their communities.  In addition, this programme assists to build capacity of young people to contribute to the economic growth of the nation, curb unemployment and urban/rural drift.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
          On the whole, there are 19 trainees who have enrolled in fashion/designing and tailoring, 17 in GSM repairs, 19 in tie and die and 9 in catering and cookery.

          </p>

          {/* <p className="text-[#666666] text-[17px] font-[300] pb-5">
            To reduce the death rates and possible complications of those ailments mentioned, YCP launched a Community Health Outreach program Tagged “CLINIC AT YOUR DOOR STEP” YCP in collaboration with the respective Local Governments and their Community Development Associations in three states of Nigeria namely, Ogun, Ondo and Lagos State.  The project is to educate and create awareness among community members about the dangers of most of these ailments threatening the health of Nigerians and Africa as a whole and possible solution for their prevention and cure.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            The health services rendered by the Clinic at your door steps program include: health  activities, health screening for hypertension, malaria and diabetes; which according to World Health Organization (WHO), reduces the life expectancy of Nigerian people. Other services include Treatment of minor ailments such as malaria, diarrhea and upper respiratory tract infection and referral to primary health centers. These services are carried out on drug revolving basis  at a cost  less than a dollar per client.
          </p> */}

        </div>
        <div className="grid md:grid-cols-4 py-10 gap-2 px-10">
          <span>  <img src="/images/skillAcquisition/sa1.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/skillAcquisition/sa2.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/skillAcquisition/sa3.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/skillAcquisition/sa4.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/skillAcquisition/sa5.JPG" alt="location" style={{}} /></span>
        </div>
      </section>

      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default AboutDetails;
