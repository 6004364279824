import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";




function Faq() {

  const [open, setOpen] = useState(1);
  const [openTestTab, setOpenTestTab] = useState(1);

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>

      {/* <!-- Start block --> */}
      <section className="bg-[#FFFFFF] body-font font-poppins pt-10 pb-40">
        <div className="mx-auto text-center pt-20">
          <p className="hidden md:block text-[#000000] md:text-3xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#FA0000]">Questions</span>
          </p>
          <p className="block md:hidden text-[#000000] text-2xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#FA0000]"><br />Questions</span>
          </p>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between  md:max-w-screen-xl  px-8 lg:px-24 pt-5 md:pt-16 pb-8 mx-auto lg:gap-20 ">
          <div className=" lg:mb-20 max-w-xl">
            {/* <div >
              <Fragment>
                <Accordion open={open === 9} className=" px-4 mb-4 bg-white border border-[#FA0000] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(9)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the Payment plan possibilities?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You can make payment of the service charges at once or 5 instalments
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 10} className=" px-4 mb-4 bg-white border border-[#FA0000] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(10)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the Payment Terms and Conditions ?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You must pay at least the first instalment of the service charge to be enrolled to the Finnish
                    program and begin classes
                    -If you decide to opt for payment by instalments, you must ensure not to default on making
                    payments as at when due or you will be removed from the class till you can pay your next
                    instalment.
                  </AccordionBody>
                </Accordion>


                <Accordion open={open === 10} className=" px-4 mb-4 bg-white border border-[#FA0000] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(10)} className='border border-white text-base font-medium text-[#170F49]'>
                    What are the Payment Terms and Conditions ?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You must pay at least the first instalment of the service charge to be enrolled to the Finnish
                    program and begin classes
                    -If you decide to opt for payment by instalments, you must ensure not to default on making
                    payments as at when due or you will be removed from the class till you can pay your next
                    instalment.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 11} className=" px-4 mb-4 bg-white border border-[#1DFA0000B459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(11)} className='border border-white text-base font-medium text-[#170F49]'>
                    In what Currency can I make payment?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    You can make payment in Euro, Dollars . For Nigerian citizens who want to pay into our local
                    bank accounts must ensure they pay the updated rates to naira.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div> */}

         

          
          

            

          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  )
}

export default Faq