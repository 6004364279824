import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";


function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false)
  const [isMenuSubMenu, serMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    serMenuSubMenu(isMenuSubMenu === false ? true : false)
  }
  return (
    <div>

      <nav class="bg-[#001F33] text-[white] fixed w-full z-20 top-0 left-0 shadow-md px-2 sm:px-4 py-3 body-font font-poppins ">
        <div class="flex justify-between  px-0 mx-auto lg:px-5">
          {/* <div className="flex justify-between"> */}
          <NavLink to="/" class="">
            <img src="/images/ycp-logo4.png" alt="" className="mt-2" style={{maxHeight:"40px"}} width={'150px'} />
          </NavLink>
          <div className=" md:hidden block md:order-2 md:pl-1 pr-1">
            <button
              onClick={() => setIsOpen(!isOpen)}
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm  rounded-lg md:hidden hover:bg-gray-100"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="red" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
            {/* </div> */}

          </div>

          <div className="items-center  justify-between hidden w-full md:flex md:w-auto " id="navbar-sticky">
            <div className="flex flex-col mt-5 font-medium md:flex-row lg:space-x-3 lg:mt-2 text-white">
              <NavLink
                to="/"
                className="block text-white py-2 pl-3 pr-4  text-sm tracking-wider font-normal leading-5 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:pb-5 "
                style={({ isActive }) =>
                  isActive ? { color: '#FA0000' } : { color: 'white' }
                }
              >
                Home
              </NavLink>

            

              <div className="relative" onMouseOut={() => setToggle((prev) => (!prev))} onMouseOver={() => setToggle((prev) => (!prev))}>
                <NavLink
                  className=" flex space-x-2 py-2 pl-3 pr-4 text-sm tracking-wider font-normal leading-5  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-[#3f71a8] lg:pb-5 "
                 
                >
                   Project
                </NavLink>
                <div className={`${toggle ? 'flex' : 'hidden'} p-2 bg-white absolute -bottom-30  mx-4 my-0 min-w-[200px] border-b-[#ff2222] border-b-4`}>
               
                  <div className=''>
                    <div className=' font-poppins flex flex-col justify-end flex-1'>
                      <NavLink to={"/project"} className='block hover:bg-[#ff2222] hover:p-2 hover:text-white font-normal cursor-pointer text-gray-700  hover:font-semibold mb-4'>Upcoming Projects</NavLink>
                      <NavLink to={"/project"} className=' block font-normal hover:bg-[#ff2222] hover:p-2 hover:text-white cursor-pointer text-gray-700  hover:font-semibold mb-3'>Ongoing Projects</NavLink>
                      <NavLink to={"/past-projects"} className=' block font-normal hover:bg-[#ff2222] hover:p-2 hover:text-white cursor-pointer text-gray-700  hover:font-semibold mb-3'>Past Projects</NavLink>

                    </div>
                  </div>
                </div>
              </div>


              <NavLink
                to="/contact-us"
                className=" flex space-x-2 py-2 pl-3 pr-4 text-sm tracking-wider font-normal leading-5  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-[#3f71a8] lg:pb-5 "
                style={({ isActive }) =>
                  isActive ? { color: '#FA0000' } : { color: 'white' }
                }
              >
                Contact&nbsp;Us
              </NavLink>
              <NavLink
                to="/about-us"
                className=" flex space-x-2 py-2 pl-3 text-sm tracking-wider font-normal leading-5  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-[#3f71a8] lg:pb-5 "
                style={({ isActive }) =>
                  isActive ? { color: '#FA0000' } : { color: 'white' }
                }
              >
                About&nbsp;Us
              </NavLink>
              {/* <NavLink
                to="/consultation"
                className=" flex space-x-2 py-2 pl-3 pr-4 text-sm tracking-wider font-normal leading-5  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-[#3f71a8] lg:pb-5 "
                style={({ isActive }) =>
                isActive ? { color: '#FA0000' } : { color: 'black' }
                }
              >
                Consultations
              </NavLink> */}
              <NavLink
                to="/faq"
                className=" flex space-x-2 py-2 pl-3 pr-4 text-sm tracking-wider font-normal leading-5  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-[#3f71a8] lg:pb-5 "
                style={({ isActive }) =>
                  isActive ? { color: '#FA0000' } : { color: 'white' }
                }
              >
                FAQ
              </NavLink>
              {/* <NavLink
                to="/apply-loan"
                className=" flex space-x-2 py-2 pl-3 pr-4 text-sm drop-shadow-2xl shadow-[#D7F5DC] tracking-wider font-bold leading-5  hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-[#3f71a8] lg:pb-5 "
                style={({ isActive }) =>
                isActive ? { color: '#FA0000' } : { color: 'black' }
                }
              >
                Apply&nbsp;for&nbsp;Loan
              </NavLink> */}








            </div>


          </div>


          <NavLink to={"/donate"} className='mt-2 hidden md:block'>
            <button type="button" class=" text-[white] bg-[#FA0000] border border-[#FA0000] font-semibold rounded-md text-sm px-6 py-2 mr-2 mb-2">Donate</button>
          </NavLink>


        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-500 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-400 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="lg:hidden bg-white w-full text-center h-screen mt-3" id="mobile-menu">
              <div ref={ref} className=" pt-10 pb-3 space-y-1" >


                <NavLink
                  to="/"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] py-2 font-bold hover:bg-gray-100"

                >
                  Home
                </NavLink>

                {/* <div className="" onClick={() => setToggle((prev) => (!prev))}>
                <NavLink
                  className=" flex justify-center lg:pb-5 text-[#616161] py-2 font-medium  "
                  // onMouseOut={() => setToggle((prev) => (!prev))}
                >
                  Programs
                </NavLink>
                <div className={`${toggle ? 'flex' : 'hidden'} p-3 bg-white absolute -bottom-30  mx-20 my-0 `}>
                  <div className=''>
                    <div className=' font-poppins flex flex-col justify-center flex-1'>
                      <NavLink to={"/programs"} onClick={() => {setIsOpen(!isOpen);setToggle(false)}} className='block font-normal text-[10px] cursor-pointer text-green-700 hover:text-gray-900 hover:font-semibold mb-4'>Learn in Finnish (No Tuition Fees Paid)</NavLink>
                      <NavLink to={"/degree-program"} onClick={() => {setIsOpen(!isOpen);setToggle(false)}} className=' block text-[10px] font-normal cursor-pointer text-green-700 hover:text-gray-900 hover:font-semibold mb-3'>Learn in English (Tuition Fees Paid)</NavLink>

                    </div>
                  </div>
                </div>
              </div> */}


                <NavLink
                  to="/project"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] py-2 font-medium hover:bg-gray-100"
                >
                  Projects
                </NavLink>


               

                {/* <NavLink
                  to="/blog"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] py-2 font-medium hover:bg-gray-100"
                >
                  Blog
                </NavLink> */}

                <NavLink
                  to="/contact-us"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] py-2 font-medium hover:bg-gray-100"
                >
                  Contact Us
                </NavLink>

                <NavLink
                  to="/about-us"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] py-2 font-medium hover:bg-gray-100"
                >
                  About Us
                </NavLink>



                <NavLink
                  to="/faq"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] py-2 font-medium hover:bg-gray-100"
                >
                  FAQ
                </NavLink>

                <NavLink to={"/donate"} onClick={() => setIsOpen(!isOpen)}
                  className="block text-[white] bg-[#FA0000] mx-10  py-2 font-medium hover:bg-gray-100">
                  Donate
                </NavLink>

              </div>
            </div>
          )}
        </Transition>
      </nav>

    </div>
  )
}

export default Navbar