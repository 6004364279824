import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from './../Navbar';
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import Map from "../Map";
import Footer from "../Footer";

const location = {
  address: 'Lautect Ogbomoso',
  lat: 8.142165,
  lng: 4.245186,
} // our location object from earlier

function ContactUs() {
  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}

      <section className="bg-[#FBFBFB] body-font font-poppins  lg:text-left md:pt-20">
        <div className="lg:mx-16 md:mx-6 mt-16 pr-8 pb-8 pt-4 bg-white rounded-lg shadow p-6">
          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:pr-10 pr-4 pt-8">
            <div>
              {/* <img src="/images/contact-us.svg" alt="location" /> */}

            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full  ">
              <div className="flex-auto md:px-16 py-10 pt-3">
                <div className="lg:px-4">
                  <h1 className="text-[#171717] md:text-3xl text-2xl font-semibold">Get in Touch with Us</h1>
                  <p className="text-xs text-[#666666] my-5">We are ready to help you in any way possible fo a smoother and easier learning process.</p>
                </div>
                <form>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 lg:px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          className="  border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 lg:px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          className="  border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-12/12 lg:px-4">
                      <div className="mb-6 ">
                        <label className="block mb-2 text-sm font-semibold text-[#666666]">
                          Email address *
                        </label>
                        <input
                          type="email"
                          className=" border border-[#FA0000] text-[#333333] text-sm rounded-lg block w-full p-3"
                          placeholder=""
                          name="l_name"
                          required

                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-12/12 lg:px-4">
                      <div className="mb-6 ">

                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-[#666666] ">Message</label>
                        <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-[#333333]  rounded-md border border-[#FA0000]" placeholder=""></textarea>

                      </div>
                    </div>
                    <div className="lg:pl-4">
                      <button
                        type="button"
                        className="py-2.5 px-6  text-sm font-medium text-white bg-[#FA0000] rounded-lg "
                      >
                        Get In Touch
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:px-10">
            <div className="border-l-2 border-[#D9D9D9]">
              <h1 className="pl-2 text-sm text-[#000000] font-semibold">Location</h1>
              <p className="pl-2 text-xs text-[#666666] mt-1">8b, Bisi Ogabi,Ikeja lagos state</p>
            </div>
            <div className="border-l-2 border-[#D9D9D9] mt-3 lg:mt-0">
              <h1 className="pl-2 text-sm text-[#000000] font-semibold">Email</h1>
              <p className="pl-2 text-xs text-[#666666] mt-1">youthcareproject2000@yahoo.com</p>
            </div>
            <div className="border-l-2 border-[#D9D9D9] mt-3 lg:mt-0">
              <h1 className="pl-2 text-sm text-[#000000] font-semibold">Telephone</h1>
              <p className="pl-2 text-xs text-[#666666] mt-1">+234...</p>
            </div>
            <div className="border-l-2 border-[#D9D9D9] mt-3 lg:mt-0">
              <h1 className="pl-2 text-sm text-[#000000] font-semibold">Office Hours</h1>
              <p className="pl-2 text-xs text-[#666666] mt-1">09:00 - 17: 00</p>
            </div>
            <div className="border-l-2 border-[#D9D9D9] mt-3 lg:mt-0">
              <h1 className="pl-2 text-sm text-[#000000] font-semibold">Follow us</h1>
              <div className="flex space-x-2 pl-2 mt-1">
                <NavLink to="https://twitter.com/afriproedu?s=21&t=A8e-6qmPz4A32mqYaoclXA" target={"_blank"} className="text-gray-500 hover:text-gray-900">
                  <SvgElement type={icontypesEnum.TWITTER} />
                </NavLink>
                <NavLink to="https://www.facebook.com/AfriProEdu?mibextid=ZbWKwL" target={"_blank"} className="text-gray-500 hover:text-gray-900">
                  <SvgElement type={icontypesEnum.FACEBOOK} />
                </NavLink>
                <NavLink to="https://instagram.com/afriproedu?igshid=YmMyMTA2M2Y=" target={"_blank"} className="text-gray-500 hover:text-gray-900">
                  <SvgElement type={icontypesEnum.INSTAGRAM} />
                </NavLink>
                <NavLink to="https://www.linkedin.com/company/afriproedu/" target={"_blank"} className="text-gray-500 hover:text-gray-900">
                  <SvgElement type={icontypesEnum.LINKEDIN} />
                </NavLink>
              </div>
            </div>

          </div>
        </div>
        {/* <div className="mx-16 mt-6 pr-8 pb-8 pt-4">
            
            <Map location={location} zoomLevel={17}/>
        </div> */}
      </section>

      {/* <!-- End block --> */}
      <section>
        <Footer/>
      </section>
    </>
  )
}
export default ContactUs;