import './App.css';
import { Routes, Route } from "react-router-dom";
//  import Navbar from './Components/Navbar';
// import Nav from './Components/Nav';

import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Project from './Components/Pages/Project'; 
import ContactUs from './Components/Pages/ContactUs';
import FaqPage from './Components/Pages/FaqPage';
import AboutDetails from './Components/Pages/AboutDetails';
import SkillAcquisition from './Components/Pages/ProjectDetails/SkillAcquisition';
import TalentHunt from './Components/Pages/ProjectDetails/TalentHunt';
import CommunityOutreach from './Components/Pages/ProjectDetails/CommunityOutreach';
import CapacityBuilding from './Components/Pages/ProjectDetails/capacityBuilding';
import Citizenship from './Components/Pages/ProjectDetails/citizenandleadership';
import Donate from './Components/Pages/Donate';
import PastProjects from './Components/Pages/pastProjects';
import Adolescent from './Components/Pages/pastProjects/adolisence';
import Adolescent2 from './Components/Pages/pastProjects/adolisence2';


function App() {
  return (
    <div>
      {/* <Navbar/> */}
      <Routes>
           <Route  path="/" element={<Home/>}/>
           <Route  path="/about-us" element={<About/>}/>
           <Route  path="/project" element={<Project/>}/>
           <Route  path="/contact-us" element={<ContactUs/>}/>
           <Route  path="/faq" element={<FaqPage/>}/>
           <Route  path="/clinic-at-your-door-step" element={<AboutDetails/>}/>
           <Route  path="/skill-acquisition-program" element={<SkillAcquisition/>}/>
           <Route  path="/talent-hunt" element={<TalentHunt/>}/>
           <Route  path="/community-outreach-activities" element={<CommunityOutreach/>}/>
           <Route  path="/capacity-building" element={<CapacityBuilding/>}/>
           <Route  path="/citizenship-and-leadership-training" element={<Citizenship/>}/>
           <Route  path="/past-projects" element={<PastProjects/>}/>
           <Route  path="/past-projects/adolescent-friendly-health-care-service" element={<Adolescent/>}/>
           <Route  path="/past-projects/adolescent-friendly-health-care-service-oke-agbe" element={<Adolescent2/>}/>
           <Route  path="/donate" element={<Donate/>}/>
      </Routes>
      {/* <Nav/> */}
    </div>
  );
}

export default App;
