import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import image from "../../../img/cb4.jpg";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../../Faq";
import WFooter from "../../WFooter";
import Footer from "../../Footer";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";


function AboutDetails() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-20 pt-[92px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-52 md:pb-8  flex justify-start "
          >
            <div className="py-20">
            <div className="text-[white] max-w-fit md:ml-44 pl-2 pr-20 mb-4 bg-black bg-opacity-80">
                <span className="font-[700] md:text-[20px] text-[20px] py-4" > Project Details</span>
                <p className="font-[700] md:text-[15px] text-[20px] py-2">Country : Nigeria</p>
                <p className="font-[700] md:text-[15px] text-[20px] py-2">Location within country : OKA AKOKO SOUTH WEST, ONDO STATE</p>
                <p className="font-[700] md:text-[15px] text-[20px] py-2">FUNDED BY Akoko South West LGAs</p>
              </div>

              <div className="text-[white] md:ml-44 pl-2 mx-12 pr-20 flex justify-start bg-[#9F0000BA] bg-opacity-80">
                <span className="font-[700] md:text-[48px] text-[20px] py-4" > ADOLESCENT FRIENDLY HEALTH CARE SERVICES AND INFORMATION FOR BAHAVIORAL CHANGE IN AKOKO SOUTH/WEST LOCAL GOVT. OKA  AKOKO SOUTH WEST, ONDO STATE</span>
              </div>
            </div>
          </div>

          {/* <div className="bg-black py-4">
            <div className="grid md:grid-cols-2 md:px-20 px-3 gap-10 pt-10">
              <div className="text-white">
                Location : Pako Field, Pako B/Stp, Dopemu Lagos.
              </div>

              <div className="text-white">
                Topic : Talent Hunt
              </div>

              <div className="text-white">
                Objective : The center also work with young people to harness their talents and potentials in sports particularly football.
              </div>
            </div>
          </div> */}
        </div>
      </section>


      <section>
        <div className="pt-10 md:px-20 px-4 pb-5">
          <div className="text-[white] max-w-fit pl-2 pr-20 flex justify-start mb-10 bg-[#FA0000] ">
            <span className="font-[700] md:text-[20px] text-[20px] py-2" > Project Overview</span>
          </div>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">Narrative description of Project: Replication of Akoko North/West with slite modification of construction of full Youth Development Centre.The project goals was to provide an accessible, affordable, effective and adolescent friendly health services and information on adolescent developmental needs thus, making them capable of taking a responsible decisions or choices. The project provides reproductive health services in a holistic way to about 20,000 adolescents.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">The project operate from a centre with community outreach activities utilizing peer health educators services render include:</p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5"> 1.	Counselling and treatment of common ailment with appropriate referrer. The project became a model, upon which other local government replicated.</p>

          <h2 className="text-[30px] font-[700]">Objectives</h2>

          <p className="text-[#666666] text-[17px] font-[300] pb-5 pt-4"> The objectives of the project are:</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 "> To increase the knowledge of 20,000 Adolescents (male/female) on their sexuality and from the risk of unwanted pregnancy and STI/HIV/AIDS after 36 months of intervention.</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 "> To promote Adolescents (male/female) responsibility for self protection and Development and provide services for them within 3 years of intervention.</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 "> To enable the Adolescents (male/female) acquired life coping skills and participate in activities directed towards their needs within the next 3 years</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">To increase Adolescents male involvement in reproductive health and lower the vulnerability of the young people within the next 3 years.</p>


          <h2 className="text-[30px] font-[700]">METHODOLOGY/ STRATEGY</h2>

          <p className="text-[#666666] text-[17px] font-[300] pb-5 pt-4"> The objectives as mentioned above were achieved by the following strategies/methods:</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 "> a. Utilisation of the Youth Development Centre</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">b.  Provision of Youth friendly Health Services and counselling</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 "> c. Peer Health Education and Youth Volunteers strategy</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">d. Outreach strategy</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">e. Networking and coalition strategy</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">f. Collaboration with the religious and community leaders, Parents and teachers</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">g. collaboration with all healthcare providers from identified private and public hospitals</p>
          <p className="text-[#666666] text-[17px] font-[300] pb-5 ">h. Promoting Recreational</p>


          {/* <p className="text-[#666666] text-[17px] font-[300] pb-5">
            There is a huge misconception of information on various health issues among community members in Nigeria.  Different studies had shown lack of knowledge, inadequate and insincere information on different health issues. Most health issues like Hypertension, Diabetes and Malaria are often misunderstood with regards to causes, prevention and treatment.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            A lot of patients in Nigeria who have been diagnosed of hypertension and diabetes are not compliant with their drugs usage, while some have no access to good drugs and this often results in complications and possible death.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            To reduce the death rates and possible complications of those ailments mentioned, YCP launched a Community Health Outreach program Tagged “CLINIC AT YOUR DOOR STEP” YCP in collaboration with the respective Local Governments and their Community Development Associations in three states of Nigeria namely, Ogun, Ondo and Lagos State.  The project is to educate and create awareness among community members about the dangers of most of these ailments threatening the health of Nigerians and Africa as a whole and possible solution for their prevention and cure.
          </p>

          <p className="text-[#666666] text-[17px] font-[300] pb-5">
            The health services rendered by the Clinic at your door steps program include: health  activities, health screening for hypertension, malaria and diabetes; which according to World Health Organization (WHO), reduces the life expectancy of Nigerian people. Other services include Treatment of minor ailments such as malaria, diarrhea and upper respiratory tract infection and referral to primary health centers. These services are carried out on drug revolving basis  at a cost  less than a dollar per client.
          </p> */}

        </div>
        {/* <div className="grid md:grid-cols-4 py-10 gap-2 px-10">
          <span>  <img src="/images/capacityBuilding/cb3.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb4.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb5.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb2.JPG" alt="location" style={{}} /></span>
          <span>  <img src="/images/capacityBuilding/cb1.JPG" alt="location" style={{}} /></span>
        </div> */}
      </section>

      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default AboutDetails;
