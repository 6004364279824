import React, { useState, useEffect } from "react";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Navbar from "../Navbar";
import image from "../../img/project.png";
import hands from "../../img/hands.png";
import volunteer from "../../img/volunteer.png";
import homemobile from "../../img/home-bg-mobile.svg";
import testbg from "../../img/test-bg.svg";
import bgbottom from "../../img/bottom.svg";
import Faq from "../Faq";
import WFooter from "../WFooter";
import Footer from "../Footer";
import { SvgElement, icontypesEnum } from "../assets/svgElement";


function Project() {

  const [open, setOpen] = useState(1);
  const [toggleText, setToggleText] = useState(1);

  const [openTestTab, setOpenTestTab] = useState(1);
  const [curImg, setCurImg] = useState(0);




  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const nextBtn = () => {
    var slider = document.getElementById("slider");

    slider!.scrollLeft = slider!.scrollLeft + 500;
  }
  const prevBtn = () => {
    var slider = document.getElementById("slider");


    slider!.scrollLeft = slider!.scrollLeft - 500;
  }



  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-20 pt-[72px]">
        <div className="">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center center'

            }}
            className="md:pt-28 md:pb-8 bg-contain lg:flex lg:flex-col "
          >
            <div className="md:mr-auto place-self-center col-span-7 flex justify-center md:px-10 px-3 md:pb-10 pb-10">
              <span className=" text-[20px] font-[500] text-[#ffffff]">Projects</span>
              <h1 className="mb-4 text-[#ffffff] font-semibold md:leading-10 pt-16 pb-5 tracking-normal">
                <span className="font-[700] md:text-[66px] text-[26px] md:leading-[70px]" > We are all about<br /> Making Lives<br /> <span className="text-[#FA0000]">Better</span></span>
              </h1>
            </div>
          </div>
        </div>

      </section>


      <section className="grid md:grid-cols-4 gap-2 mx-1">

        <div className='m-2 '>
          <div className="bg-white max-w-sm pb-10 cursor-pointer mt-5 px-10 py-6 border rounded-lg shadow-lg">
            <div className="flex justify-center">
              <img src="/images/cad2.jpeg" alt="location" style={{ width: "100%" }} />
            </div>
            <div className="flex justify-between pt-5 pb-3">
              <div className="flex flex-col">
                <span className=" text-[16px] font-[700] pb-2">CLINIC AT YOUR DOORSTEP </span>
                <div className="mb-2 mt-2">
                  <NavLink to={'/clinic-at-your-door-step'}
                    className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                  >
                    Read More
                  </NavLink>

                </div>

              </div>
              {/* <div className="flex">
                <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                <span>20</span>
              </div> */}

            </div>

            {/* <div className="bg-[black] rounded-md py-2 px-2">
              <span
                className=" md:px-1 px-1 text-sm font-medium text-white "
                style={{maxHeight:"2vh"}}
              >This project/event aims at promoting health as well as narrowing the gaps and reducing inequalities.
              </span>
            </div> */}

          </div>
        </div>

        <div className='m-2 '>
          <div className="bg-white max-w-sm pb-5  cursor-pointer mt-5 px-10 py-6 border rounded-lg shadow-lg">
            <div className="flex justify-center">
              <img src="/images/skillAcquisition/sa5.JPG" alt="location" style={{ width: "100%" }} />
            </div>
            <div className="flex justify-between pt-5 pb-3">
              <div className="flex flex-col">
                <span className=" text-[16px] font-[700] pb-2">SKILLS ACQUISITION PROGRAM </span>
                <div className="mb-2 mt-2">
                  <div className="mb-2 mt-2">
                    <NavLink to={'/skill-acquisition-program'}
                      className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                    >
                      Read More
                    </NavLink>

                  </div>

                </div>

              </div>
              {/* <div className="flex">
                <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                <span>20</span>
              </div> */}

            </div>

            {/* <div className="bg-[black] rounded-md py-2 px-2">
              <span
                className=" md:px-1 px-1 text-sm font-medium text-white  "
              >At the vocational training and skill acquisition center
              </span>
            </div> */}

          </div>
        </div>

        <div className='m-2 '>
          <div className="bg-white max-w-sm pb-10  cursor-pointer mt-5 px-10 py-6 border rounded-lg shadow-lg">
            <div className="flex justify-center">
              <img src="/images/talentHunt/th1.JPG" alt="location" style={{ width: "100%" }} />
            </div>
            <div className="flex justify-between pt-5 pb-3">
              <div className="flex flex-col">
                <span className=" text-[16px] font-[700] pb-2">TALENT HUNT</span>
                <div className="mb-2 mt-2">
                  <NavLink to={'/talent-hunt'}
                    className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                  >
                    Read more
                  </NavLink>

                </div>

              </div>


            </div>

            {/* <div className="bg-[black] rounded-md py-2 px-2">
              <span
                className=" md:px-1 px-1 text-sm font-medium text-white  "
              >The center also work with young people to harness their talents and potentials in sports particularly football.
              </span>
            </div> */}

          </div>
        </div>

        {/* <div className='m-2 '>
          <div className="bg-white max-w-sm pb-10 border rounded-lg  cursor-pointer mt-5 px-10 py-6  shadow-lg">
            <div className="flex justify-center">
              <img src="/images/clinic.webp" alt="location" style={{ width: "100%" }} />
            </div>
            <div className="flex justify-between pt-5 pb-3">
              <div className="flex flex-col">
                <span className=" text-[14px] font-[700] pb-2"> COMMUNITY OUTREACH ACTIVITIES</span>
                <div className="mb-2 mt-2">
                <NavLink to={'/community-outreach-activities'}
                    className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                  >
                    Read More
                  </NavLink>

                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div className='m-2 '>
          <div className="bg-white max-w-sm pb-10 border rounded-lg  cursor-pointer mt-5 px-10 py-6  shadow-lg">
            <div className="flex justify-center">
              <img src="/images/anualMeeting/a3.jpg" alt="location" style={{ width: "100%" }} />
            </div>
            <div className="flex justify-between pt-5 pb-3">
              <div className="flex flex-col">
                <span className=" text-[14px] font-[700] pb-2"> CITIZENSHIP AND LEADERSHIP TRAINING</span>
                <div className="mb-2 mt-2">
                  <NavLink to={'/citizenship-and-leadership-training'}
                    className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                  >
                    Read More
                  </NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='m-2 '>
          <div className="bg-white max-w-sm pb-10  cursor-pointer mt-5 px-10 py-6 border rounded-lg shadow-lg">
            <div className="flex justify-center">
              <img src="/images/capacityBuilding/cb4.JPG" alt="location" style={{ width: "100%" }} />
            </div>
            <div className="flex justify-between pt-5 pb-3">
              <div className="flex flex-col">
                <span className=" text-[16px] font-[700] pb-2"> CAPACITY BUILDING FOR STAFF</span>
                <div className="mb-2 mt-2">
                  <NavLink to={'/capacity-building'}
                    className="py-2.5 md:px-5 mb-2  px-2 text-sm font-medium text-white bg-[#FA0000] rounded-lg border border-[#FA0000] "
                  >
                    Read More
                  </NavLink>

                </div>

              </div>
              {/* <div className="flex">
                <span> <SvgElement type={icontypesEnum.PROFILE} /> </span>
                <span>20</span>
              </div> */}

            </div>

            {/* <div className="bg-[black] rounded-md py-2 px-2">
              <span
                className=" md:px-1 px-1 text-sm font-medium text-white  "
              >The objective of the workshop was to improve the knowledge, skills and competencies of workers towards better performance
              </span>
            </div> */}

          </div>
        </div>



      </section>






      <Footer />


      {/* <!-- End block --> */}

    </>
  );
}

export default Project;
